import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  min-inline-size: 334px;
  max-inline-size: 672px;
  padding-block: 14px;
  padding-inline: 16px;

  color: rgb(255, 255, 255);

  border-radius: 4px;

  background-color: rgb(50, 50, 50);

  box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px, rgba(0, 0, 0, 0.14) 0 6px 10px 0,
    rgba(0, 0, 0, 0.12) 0 1px 18px 0;
  -webkit-box-align: center;
`;
