import type { CustomContentProps } from "notistack";
import { SnackbarContent } from "notistack";
import { forwardRef } from "react";

import { sendGATracking } from "~/components/tracking/google-analytics/apis";

import { ArrowIcon, CloseIcon, Content, Message, PhoneIcon } from "./styles";

export type AppPromotionSnackbarItemProps = { onClickClose?: () => void };
export type Props = AppPromotionSnackbarItemProps & CustomContentProps;

export const AppPromotionSnackbarItem = forwardRef<HTMLDivElement, Props>(
  ({ onClickClose }, reference) => {
    const handleClick = () => {
      sendGATracking({
        action: "App Download/FB Inapp browser/Click",
        category: "App Download",
        label: window.location.href,
      });
      window.location.href = "https://scmp.onelink.me/3586748601/thaczxeh";
    };

    const handleClose = () => {
      sendGATracking({
        action: "App Download/FB Inapp browser/Close",
        category: "App Download",
        label: window.location.href,
      });
      onClickClose?.();
    };

    return (
      <SnackbarContent ref={reference} role="banner">
        <Content onClick={handleClick}>
          <Message>
            Try this article in our <br />
            <b>mobile app</b>
          </Message>
          <PhoneIcon />
          <ArrowIcon />
          <CloseIcon onClick={handleClose} />
        </Content>
      </SnackbarContent>
    );
  },
);

AppPromotionSnackbarItem.displayName = "AppPromotionSnackbarItem";
